import * as React from "react"
import { useState, useEffect } from "react"
import { Link } from "gatsby"
import MainSlider from "./main-slider"
import Navbar from "./navbar"
import Footer from "./footer"

const Layout = ({ location, title, children }) => {
  const rootPath = `${__PATH_PREFIX__}/`
  const isRootPath = location.pathname === rootPath

  const [isLoaded, setIsLoaded] = useState(false)

  useEffect(() => {
    setIsLoaded(!isLoaded)
  }, [])

  // let header

  // if (isRootPath) {
  //   header = (
  //     <h1 className="main-heading">
  //       <Link to="/">{title}</Link>
  //     </h1>
  //   )
  // } else {
  //   header = (
  //     <Link className="header-link-home" to="/">
  //       {title}
  //     </Link>
  //   )
  // }

  return (
    <>
      <Navbar
        fadeInInit={`is-fade-in-ready ${isLoaded ? "fade-in-init" : ""}`}
      />
      <MainSlider
        pageTitle={title}
        fadeInInit={`is-fade-in-ready ${isLoaded ? "fade-in-init" : ""}`}
      />
      <div className="container" data-is-root-path={isRootPath}>
        {/* <header className="global-header">{header}</header> */}
        <main>{children}</main>
      </div>
      <Footer />
    </>
  )
}

export default Layout
