import * as React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import Slider from "react-slick"

const MainSlider = ({ fadeInInit, pageTitle }) => {
  const data = useStaticQuery(graphql`
    query SliderQuery {
      allMarkdownRemark(
        filter: { fileAbsolutePath: { regex: "/(slider)/" } }
        sort: { fields: [frontmatter___date], order: DESC }
        ) {
        nodes {
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            description
            featuredImage {
              childImageSharp {
                gatsbyImageData(layout: CONSTRAINED)
              }
            }
          }
        }
      }
    }
  `)

  const slides = data.allMarkdownRemark.nodes

  const settings = {
    dots: true,
    infinite: true,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    speed: 2000,
    autoplaySpeed: 10000,
    fade: true,
    className: "",
  }

  return (
    <div className="slick-slider-limiter ">
      <Slider {...settings}>
        {slides.map(slide => {
          const title = slide.frontmatter.title
          const featuredImage = getImage(slide.frontmatter.featuredImage)
          return (
            <div key={slide.frontmatter.date}>
              <section className="hero hero-slide-container">
                <GatsbyImage
                  objectFit="cover"
                  objectPosition="50% 80%"
                  image={featuredImage}
                  alt={title}
                  style={{ height: "600px" }}
                />
                <div className={`hero-body ${fadeInInit}`}>
                  <div className="container">
                    <p className="title is-family-secondary has-text-whitish is-size-3 has-letter-spacing has-text-shadow">
                      WOJCIECH RUTKOWSKI
                    </p>
                    <p className="is-uppercase subtitle has-text-whitish is-size-5 has-letter-spacing has-text-weight-medium has-text-shadow-strong has-horizontal-line">
                      {pageTitle}
                    </p>
                  </div>
                </div>
              </section>
              <div className={`inner-shadow ${fadeInInit}`}></div>
            </div>
          )
        })}
      </Slider>
    </div>
  )
}

export default MainSlider
