import React, { useState } from "react"
import { Link } from "gatsby"
import { StaticImage } from "gatsby-plugin-image"

const Navbar = ({ fadeInInit }) => {
  const [navState, setNavState] = useState(false)

  function navToggle() {
    setNavState(!navState)
  }

  return (
    <nav
      className={`navbar ${navState ? "navbar-mobile" : ""} ${fadeInInit}`}
      role="navigation"
      aria-label="main navigation"
    >
      <div className="navbar-brand">
        <Link className="navbar-item" to="/">
          <StaticImage
            src="../images/logo_rutkowski_transparent.png"
            alt="logo Wojciech Rutkowski"
            placeholder="blurred"
            layout="fixed"
            height={60}
            style={{
              display: "flex",
            }}
          />
        </Link>
        <a
          role="button"
          className={`navbar-burger ${navState ? "is-active" : ""}`}
          aria-label="menu"
          aria-expanded="false"
          data-target="navbarBasicExample"
          onClick={navToggle}
        >
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
          <span aria-hidden="true"></span>
        </a>
      </div>
      <div
        id="navbarBasicExample"
        className={`navbar-menu ${navState ? "is-active" : ""}`}
      >
        <div className="navbar-end">
          <Link
            className="navbar-item navbar-item-mobile is-size-6 has-text-weight-medium has-letter-spacing link-whitish"
            to="/zyciorys"
            activeClassName="menu-item-active"
          >
            ŻYCIORYS
          </Link>
          <Link
            className="navbar-item navbar-item-mobile is-size-6 has-text-weight-medium has-letter-spacing"
            to="/galeria"
            activeClassName="menu-item-active"
          >
            GALERIA
          </Link>
          <Link
            className="navbar-item navbar-item-mobile is-size-6 has-text-weight-medium has-letter-spacing"
            to="/przyjaciele"
            activeClassName="menu-item-active"
          >
            PRZYJACIELE
          </Link>
          <Link
            className="navbar-item navbar-item-mobile is-size-6 has-text-weight-medium has-letter-spacing"
            to="/do-pobrania"
            activeClassName="menu-item-active"
          >
            DO POBRANIA
          </Link>
          <Link
            className="navbar-item navbar-item-mobile is-size-6 has-text-weight-medium has-letter-spacing"
            to="/kontakt"
            activeClassName="menu-item-active"
          >
            KONTAKT
          </Link>
          <a
            className="navbar-item navbar-item-mobile menu-close-button is-size-6 has-text-weight-medium has-letter-spacing is-hidden-desktop"
            onClick={navToggle}
          >
            ZAMKNIJ MENU
          </a>
        </div>
      </div>
    </nav>
  )
}

export default Navbar
