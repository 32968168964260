import * as React from "react"
import { Link } from "gatsby"

const Footer = ({ location }) => {
  return (
    <footer className="mt-6 pb-6 has-background-more-dark">
      <div className="container">
        <div className="columns">
          <div className="column is-flex is-flex-direction-column pt-5 footer-left is-desktop">
            <Link
              className="is-size-7 has-text-weight-light has-letter-spacing mb-1"
              to="/zyciorys"
            >
              ŻYCIORYS
            </Link>
            <Link
              className="is-size-7 has-text-weight-light has-letter-spacing mb-1"
              to="/galeria"
            >
              GALERIA
            </Link>
            <Link
              className="is-size-7 has-text-weight-light has-letter-spacing mb-1"
              to="/przyjaciele"
            >
              PRZYJACIELE
            </Link>
            <Link
              className="is-size-7 has-text-weight-light has-letter-spacing mb-1"
              to="/do-pobrania"
            >
              DO POBRANIA
            </Link>
            <Link
              className="is-size-7 has-text-weight-light has-letter-spacing mb-1"
              to="/kontakt"
            >
              KONTAKT
            </Link>
          </div>
          <div className="column has-text-centered footer-middle pt-5">
            <div className="is-size-7 is-family-secondary has-text-weight-light has-letter-spacing mb-3">
              WOJCIECH RUTKOWSKI
            </div>
            <div className="is-size-7 is-family-secondary has-text-weight-light has-letter-spacing has-divider-thin mb-2">
              ARTYSTA MALARZ
            </div>
            <a
              target="_blank"
              href="https://m.facebook.com/pages/biz/warszawa/Wojciech-Rutkowski-125242080861242/?locale2=pl_PL"
            >
              <div className="material-icons-outlined md-18">facebook</div>
            </a>
            <div className="is-size-7 is-family-secondary has-text-weight-light has-letter-spacing mt-6">
              © 2021 OBRAZYRUTKOWSKI.PL
            </div>
          </div>
          <div className="column has-text-right pt-5 footer-right">
            <div className="is-size-7 has-text-weight-light has-letter-spacing mb-1">
              KONTAKT
            </div>

            <div className="is-size-7 has-text-weight-light has-letter-spacing mb-1">
              <a
                className="is-uppercase"
                href={`mailto:wojrut6@wp.pl?subject=Kontakt ze strony obrazyrutkowski.pl`}
                target="_blank"
              >
                wojrut6@wp.pl
              </a>
            </div>
            <div className="is-size-7 has-text-weight-light has-letter-spacing mb-2">
              +48 608 835 610
            </div>
            <div className="is-uppercase is-size-7 has-text-weight-light has-letter-spacing mb-1">
              ul. Człuchowska 19
            </div>
            <div className="is-uppercase is-size-7 has-text-weight-light has-letter-spacing mb-1">
              01-360 Warszawa
            </div>
          </div>
        </div>
      </div>
    </footer>
  )
}
export default Footer
